import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import api from 'services/api';

export const getReports = createAsyncThunk(
  'reportsPage/getReports',
  async () => {
    const response = await api.get('file');
    const { data } = await response.data;

    return data.map((report) => ({ ...report, id: report.updatedAt }));
  }
);

export const createReport = createAsyncThunk(
  'reportsPage/createReport',
  async (dates) => {
    api.get('report/overview-demonstrative', {
      params: {
        ...dates,
      },
    });

    const fileNameTemp = `fileType_${dates.start}_${dates.end}_~`;

    return { name: fileNameTemp, id: '01' };
  }
);

const reportsPageAdapter = createEntityAdapter({});

export const {
  selectAll: selectReports,
  selectEntities: selectReportsEntities,
  selectById: selectReportById,
} = reportsPageAdapter.getSelectors((state) => state.reportsPage);

const reportsPageSlice = createSlice({
  name: 'reportsPage',
  initialState: reportsPageAdapter.getInitialState({}),
  reducers: {},
  extraReducers: {
    [getReports.fulfilled]: (state, { payload }) => {
      reportsPageAdapter.setAll(state, payload);
    },

    [createReport.fulfilled]: (state, { payload }) => {
      reportsPageAdapter.addOne(state, payload);

      const { id } = payload;
      state.ids = [id, ...state.ids.filter((existingId) => existingId !== id)];
    },
  },
});

export default reportsPageSlice.reducer;
