import {
  LAYOUT,
  MENU_BEHAVIOUR,
  NAV_COLOR,
  MENU_PLACEMENT,
  RADIUS,
  THEME_COLOR,
  USER_ROLE,
} from 'constants.js';

export const IS_DEMO = false;
export const IS_AUTH_GUARD_ACTIVE = true;
export const SERVICE_URL = '/app';
export const USE_MULTI_LANGUAGE = true;
const CLIENT_NAME = 'Didati';

// For detailed information: https://github.com/nfl/react-helmet#reference-guide
export const REACT_HELMET_PROPS = {
  defaultTitle: CLIENT_NAME,
};

export const DEFAULT_PATHS = {
  APP: '/',
  LOGIN: '/login',
  REGISTER: '/register',
  STUDENT_REGISTER: '/cadastrar-aluno',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/password-update',
  USER_WELCOME: '/dashboards/default',
  NOTFOUND: '/page-not-found',
  UNAUTHORIZED: '/unauthorized',
  INVALID_ACCESS: '/invalid-access',
  USE_TERM: '/termos-de-uso',
  PRIVACY_POLICIES: '/politicas-de-privacidade',
};

export const DEFAULT_SETTINGS = {
  MENU_PLACEMENT: MENU_PLACEMENT.Vertical,
  MENU_BEHAVIOUR: MENU_BEHAVIOUR.Unpinned,
  LAYOUT: LAYOUT.Boxed,
  RADIUS: RADIUS.Rounded,
  COLOR: THEME_COLOR.LightBlue,
  NAV_COLOR: NAV_COLOR.Default,
  USE_SIDEBAR: false,
};

export const DEFAULT_USER = {
  id: 1,
  name: 'Lab Pedimos',
  thumb: '/img/profile/profile-default.png',
  role: USER_ROLE.Admin,
  email: 'lab@pedimos.com.br',
  educationalInstitutionId: '0e31b292-c771-4a4d-9f67-ffbc073632a7',
};

export const BUNDLE_ID_BY_DOMAIN = {
  'smelj.didati.com.br': 'br.com.didati.smelj',
  'localhost:3000': 'dev.pdm.edu',
  'edu.pdm.dev': 'dev.pdm.edu',
};

export const ALLOWED_DOMAINS_FOR_STUDENT_SIGN_UP =
  Object.keys(BUNDLE_ID_BY_DOMAIN);

export const REDUX_PERSIST_KEY = 'elearning-portal';
