// import redux and persist plugins
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'reduxjs-toolkit-persist';
import storage from 'reduxjs-toolkit-persist/lib/storage';
import persistStore from 'reduxjs-toolkit-persist/es/persistStore';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'reduxjs-toolkit-persist/es/constants';

// import theme reducers
import settingsReducer from 'settings/settingsSlice';
import layoutReducer from 'layout/layoutSlice';
import langReducer from 'i18n/langSlice';
import authReducer from 'auth/authSlice';
import menuReducer from 'layout/nav/main-menu/menuSlice';
import menuYearReducer from 'layout/nav/menuYear/menuYearSlice';
import notificationsReducer from 'views/notification/notificationsSlice';
import scrollspyReducer from 'components/scrollspy/scrollspySlice';
import instructorsReducer from 'views/users/instructor/instructorsSlice';
import studentsReducer from 'views/users/students/studentsSlice';
import trainingTypesReducer from 'views/parameters/training/trainingTypesSlice';
import curriculumMatrixReducer from 'views/curriculumMatrix/curriculumMatrixSlice';
import gradesReducer from 'views/parameters/grades/gradesSlice';
import knowledgeAreaReducer from 'views/parameters/knowledge/knowledgeAreaSlice';
import curriculumComponentReducer from 'views/parameters/curriculum/curriculumComponentSlice';
import educationLevelsReducer from 'views/parameters/education/educationLevelsSlice';
import lessonPlansReducer from 'views/lessonPlans/lessonPlansSlice';
import lessonPlanReducer from 'views/lessonPlan/lessonPlanSlice';
import lessonsReducer from 'views/lessons/lessonSlice';
import recurrencesReducer from 'views/lessonPlan/recurrenceTab/recurrenceSlice';
import eventsCalendarReducer from 'views/eventsCalendar/eventsCalendarSlice';
import eventsReducer from 'views/eventsCalendar/eventsSlice';
import academicYearReducer from 'views/parameters/academicYear/academicYearSlice';
import registrationReducer from 'views/registration/registrationSlice';
import instructorClassesReducer from 'views/instructorView/classesPage/classesPageSlice';
import frequencyReducer from 'views/instructorView/frequencyPage/frequencySlice';
import classroomReducer from 'views/instructorView/classPage/classroomSlice';
import evaluationsReducer from 'views/evaluations/evaluationsSlice';
import evaluationScoresReducer from 'views/instructorView/evaluationScores/evaluationScoresSlice';
import profileReducer from 'views/profile/profileSlice';
import studentEnrollmentReducer from 'views/studentEnrollment/studentEnrollmentSlice';
import instructorDashReducer from 'views/dashboards/instructor/instructorDashSlice';
import studentHomeReducer from 'views/studentHome/studentHomeSlice';
import reportReducer from 'report/reportSlice';
import reportsPageReducer from 'views/report/reportsPageSlice';

// import persist key
import { REDUX_PERSIST_KEY } from 'config.js';

const persistConfig = {
  key: REDUX_PERSIST_KEY,
  storage,
  whitelist: ['menu', 'menuYear', 'settings', 'lang', 'auth'],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    academicYear: academicYearReducer,
    auth: authReducer,
    classroom: classroomReducer,
    curriculumComponent: curriculumComponentReducer,
    curriculumMatrix: curriculumMatrixReducer,
    educationLevels: educationLevelsReducer,
    evaluationScores: evaluationScoresReducer,
    evaluations: evaluationsReducer,
    events: eventsReducer,
    eventsCalendar: eventsCalendarReducer,
    frequencies: frequencyReducer,
    grades: gradesReducer,
    instructorClasses: instructorClassesReducer,
    instructorDash: instructorDashReducer,
    instructors: instructorsReducer,
    knowledgeArea: knowledgeAreaReducer,
    lang: langReducer,
    layout: layoutReducer,
    lessonPlan: lessonPlanReducer,
    lessonPlans: lessonPlansReducer,
    lessons: lessonsReducer,
    menu: menuReducer,
    menuYear: menuYearReducer,
    notifications: notificationsReducer,
    profile: profileReducer,
    recurrences: recurrencesReducer,
    registration: registrationReducer,
    report: reportReducer,
    reportsPage: reportsPageReducer,
    scrollspy: scrollspyReducer,
    studentEnrollment: studentEnrollmentReducer,
    settings: settingsReducer,
    students: studentsReducer,
    studentHome: studentHomeReducer,
    trainingTypes: trainingTypesReducer,
  })
);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
const persistedStore = persistStore(store);
export { store, persistedStore };
