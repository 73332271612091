import menuPtMessages from 'i18n/messages/menu/menuPt';
import dashPtMessages from 'i18n/messages/dashboard/dashPt';
import usersPtMessages from 'i18n/messages/users/usersPt';
import validationPtMessages from 'i18n/messages/validate/Validation';
import parametersPtMessages from 'i18n/messages/parameters/parametersPt';
import commonsPtMessages from 'i18n/messages/commons/commonsPt';
import loginPtMessages from 'i18n/messages/login/loginPt';
import curriculumMatrixPtMessages from 'i18n/messages/curriculumMatrix/curriculumMatrixPt';
import lessonPlansPtMessages from 'i18n/messages/lessonPlans/lessonPlansPt';
import calendarPtMessages from 'i18n/messages/eventsCalendar/eventsCalendarPt';
import notificationsPtMessages from 'i18n/messages/notifications/notificationsPt';
import historyPtMessages from 'i18n/messages/history/historyPt';
import registrationPtMessages from 'i18n/messages/registration/registrationPt';
import instructorProfilePtMessages from 'i18n/messages/instructorProfile/instructorProfilePt';
import apiPtMessages from 'i18n/messages/api/apiPt';
import instructorClassesPtMessages from 'i18n/messages/instructorClasses/instructorClassesPt';
import typePtMessages from 'i18n/messages/type/typePt';
import footerPtMessages from 'i18n/messages/footer/footerPt';
import reportPtMessages from 'i18n/messages/reports/reportPt';
import tourPtMessages from 'i18n/messages/tour/tourPt';

const ptMessages = {
  ...menuPtMessages,
  ...loginPtMessages,
  ...dashPtMessages,
  ...usersPtMessages,
  ...validationPtMessages,
  ...commonsPtMessages,
  ...curriculumMatrixPtMessages,
  ...lessonPlansPtMessages,
  ...registrationPtMessages,
  ...calendarPtMessages,
  ...notificationsPtMessages,
  ...historyPtMessages,
  ...instructorProfilePtMessages,
  ...apiPtMessages,
  ...instructorClassesPtMessages,
  ...parametersPtMessages,
  ...typePtMessages,
  ...footerPtMessages,
  ...reportPtMessages,
  ...tourPtMessages,
};

export default ptMessages;
