import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

const classroomAdapter = createEntityAdapter({});

const classroomSlice = createSlice({
  name: 'classroom',
  initialState: classroomAdapter.getInitialState({
    filter: {},
  }),
  reducers: {
    handleChange: (state, { payload }) => {
      state.filter = { ...state.filter, ...payload };
    },
  },
  extraReducers: {},
});

export const { handleChange } = classroomSlice.actions;

export default classroomSlice.reducer;
