import { lazy } from 'react';
import { USER_ROLE } from 'constants.js';
import { DEFAULT_PATHS } from 'config.js';

const report = {
  report: lazy(() => import('views/report/ReportsPage')),
};
const appRoot = DEFAULT_PATHS.APP.endsWith('/')
  ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length)
  : DEFAULT_PATHS.APP;

const reportRoute = {
  path: `${appRoot}/relatorio`,
  exact: true,
  icon: 'arrow-end-bottom',
  label: 'commons.reports',
  component: report.report,
  protected: true,
  roles: [USER_ROLE.Admin],
  to: `${appRoot}/relatorio`,
  isReport: true,
};

export default reportRoute;
