const reportPtMessages = {
  'report.access-report': 'Acessar relatório',

  'report.generate-report': 'Gerar relatório',
  'report.generating': 'Gerando...',

  'report.overview-demonstrative-report': 'Relatório demonstrativo',
  'report.overview-demonstrative': 'Demonstrativo geral',
};

export default reportPtMessages;
