import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import moment from 'moment';
import api from 'services/api';
import { datePatterns } from 'shared/constants/datePatterns';
import dateFormatter from 'shared/dateFormatters/dateFormatter';

export const getLessonPlansToDash = createAsyncThunk(
  'instructorDash/getLessonPlansToDash',
  async ({ currentPage }, { getState, dispatch }) => {
    const {
      auth: { currentUser },
      menuYear: { selectedYear },
    } = getState();

    const response = await api.get('lesson-plan', {
      params: {
        academicYearId: selectedYear.id,
        instructorId: currentUser.id,
        currentPage: currentPage ?? 1,
      },
    });
    const data = await response.data;

    if (data.currentPage < data.totalPages) {
      dispatch(getLessonPlansToDash(data.currentPage + 1));
    }

    return response.data;
  }
);

export const getLessonEvents = createAsyncThunk(
  'instructorDash/getLessonEvents',
  async (_, { getState }) => {
    const {
      instructorDash: { lessonPlans },
    } = getState();

    const allResponseData = await Promise.all(
      lessonPlans.map(async (lessonPlan) => {
        const lessons = await api.get(`lesson-plan/${lessonPlan.id}/lesson`, {
          params: {
            start: dateFormatter({
              date: new Date(),
              pattern: datePatterns.yearMonthDay,
            }),
            end: dateFormatter({
              date: new Date(),
              pattern: datePatterns.yearMonthDay,
            }),
          },
        });

        return lessons.data.data.map((lesson) => ({ ...lesson, lessonPlan }));
      })
    );

    const lessons = allResponseData.reduce((responses, data) => {
      return [...responses, ...data];
    }, []);

    return lessons;
  }
);

export const getLessonOverview = createAsyncThunk(
  'instructorDash/getLessonOverview',
  async (_, { getState }) => {
    const {
      instructorDash: { lessonPlans },
    } = getState();

    const allResponseData = await Promise.all(
      lessonPlans.map(async (lessonPlan) => {
        const academicTerm = lessonPlan.grade.educationLevel.academicTerms.find(
          (term) => moment().isBetween(moment(term.start), moment(term.end))
        );

        try {
          const response = await api.get(
            `lesson-plan/${lessonPlan.id}/academic_term/${academicTerm.id}`
          );
          return { ...response.data, lessonPlan };
        } catch (error) {
          return null;
        }
      })
    );

    return allResponseData.filter((data) => data !== null);
  }
);

export const getInstructorNotifications = createAsyncThunk(
  'instructorDash/getInstructorNotifications',
  async ({ currentPage }, { getState }) => {
    const {
      notifications: { pagination },
    } = getState();

    const { data } = await api.get('school-message', {
      params: {
        currentPage: currentPage ?? pagination.currentPage,
      },
    });

    return data;
  }
);

const instructorDashAdapter = createEntityAdapter({});

const instructorDashSlice = createSlice({
  name: 'instructorDash',
  initialState: instructorDashAdapter.getInitialState({
    lessonPlans: [],
    lessons: [],
    notifications: [],
    lessonOverview: [],
    dashEvent: {
      show: false,
      data: '',
    },
    notificationModal: {
      show: false,
      data: '',
    },
    lessonEventsLoading: true,
  }),

  reducers: {
    openDashEventModal: (state, { payload }) => {
      state.dashEvent = { data: payload, show: true };
    },
    closeDashEventModal: (state) => {
      state.dashEvent = { data: '', show: false };
    },
    updateDashLesson: (state, { payload }) => {
      const lesson = state.lessons.find(({ id }) => id === payload.id);
      if (!lesson) return;

      const filteredLessons = state.lessons.filter(
        ({ id }) => id !== payload.id
      );
      state.lessons = [
        ...filteredLessons,
        { ...payload, lessonPlan: lesson.lessonPlan },
      ];
    },

    openNotificationModal: (state, { payload }) => {
      state.notificationModal = { data: payload, show: true };
    },
    closeNotificationModal: (state) => {
      state.notificationModal = { data: '', show: false };
    },
  },

  extraReducers: {
    [getLessonPlansToDash.fulfilled]: (state, { payload }) => {
      if (payload.currentPage === 1) {
        state.lessonPlans = payload.data;
      } else {
        state.lessonPlans = [...state.lessonPlans, ...payload.data];
      }
    },

    [getLessonEvents.pending]: (state) => {
      state.lessonEventsLoading = true;
    },

    [getLessonEvents.fulfilled]: (state, { payload }) => {
      state.lessons = payload;
      state.lessonEventsLoading = false;
    },

    [getInstructorNotifications.fulfilled]: (state, { payload }) => {
      state.notifications = payload;
    },

    [getLessonOverview.pending]: (state) => {
      state.lessonOverviewLoading = true;
    },

    [getLessonOverview.fulfilled]: (state, { payload }) => {
      state.lessonOverview = payload;
    },
  },
});

export const {
  openDashEventModal,
  closeDashEventModal,
  updateDashLesson,
  openNotificationModal,
  closeNotificationModal,
} = instructorDashSlice.actions;

export default instructorDashSlice.reducer;
