import axios from 'axios';
import { UserAuthService } from 'services/pdmAuth';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.response.use(
  (config) => config,

  (error) => {
    if (error.response.status === 401) {
      UserAuthService.signOut();
    }
    return Promise.reject(error);
  }
);

export default api;
